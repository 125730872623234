import { blue, green, purple, red, yellow } from "@mui/material/colors";
import { MapCameraProps } from "@vis.gl/react-google-maps";
import { SortingRules } from "constants/trash";
import { GeoPoint } from "firebase/firestore";
import { CollectionType } from "./Collection";

export enum PartnerStatus {
    ONBOARDING = "onboarding",
    ACTIVE = "active",
    INACTIVE = "inactive",
    ARCHIVED = "archived",
    DEMO = "demo",
}

export type WasteTypeAccess = {
    [key in CollectionType]: boolean;
};

export const PARTNER_STATUSES_COLORS = {
    [PartnerStatus.ONBOARDING]: purple[400],
    [PartnerStatus.ACTIVE]: green[400],
    [PartnerStatus.INACTIVE]: yellow[400],
    [PartnerStatus.ARCHIVED]: red[400],
    [PartnerStatus.DEMO]: blue[400],
}

export type PartnerDbData = {
    ID: string;
    name: string;
    status: PartnerStatus;
    defaultMapViewport?: {
        center: GeoPoint;
        zoom: number;
    };
    logoURL: string;

    /** If set to true, let partner edit some of their settings (collections schedules...). */
    canAccessSettings?: boolean;

    /** Whether the partner can use the "Explorer" section. */
    canAccessExplorer?: boolean;

    /** Indicate for which waste streams the partner has access to the collections data. */
    canAccessWasteType?: Partial<WasteTypeAccess>;

    /** 
     * Indicate which collection different wastes belong to 
     * (other than default values, see DEFAULT_SORTING_RULES).
     * @example 
     * ```
     * {
     *   "polystyrene": ["sortable_waste"],
     *   "bigCardboard": ["landfill", "big_cardboard"]
     * }
     * ```
     * indicate that for this partner, polystyrene is not an error is sortable waste collection,
     * wherease big cardboards are, since they have a dedicated collection or should be brought to landfill. 
     */
    sortingRules?: Partial<SortingRules>;
}

export type NewPartnerData = Pick<PartnerDbData, "name" | "status" | "logoURL">;

export enum PartnerType {
    BAILLEUR = "bailleur", 
    COLLECTIVITY = "collectivity", 
    COLLECTION_OPERATOR = "collection_operator", 
    COMPANY = "company", 
    OTHER = "other",
}

type Partner = Omit<PartnerDbData, "defaultMapViewport"> & {
    ID: string;
    defaultMapViewport?: MapCameraProps;
}
export default Partner;