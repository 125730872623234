import { Grid, GridProps } from '@mui/material';
import DatesSelect from 'components/_include/Filters/DatesSelect';
import TruckSelect from 'components/_include/Filters/TruckSelect';
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { DatesRangeType, isSingleDay } from 'helpers/dates';
import moment from 'moment';
import { useAppSelector } from 'store/hooks';
import { SearchParam } from 'constants/urls';
import ErrorsSelect from 'components/_include/Filters/ErrorsSelect';
import TimesRangeSelect from 'components/_include/Filters/TimeSelect/TimesRangeSelect';
import AreaSelect from 'components/_include/Filters/AreaSelect';
import RouteSelect from 'components/_include/Filters/RouteSelect';

const FilterWrapper = (props: GridProps) => (
    <Grid
        item
        justifyContent="center"
        display="flex"
        {...props}
    />
);

function ExplorerFilters() {
    const [searchParams] = useSearchParams();
    const truckID = searchParams.get(SearchParam.TRUCK_ID) ?? undefined;
    const areaID = searchParams.get(SearchParam.AREA_ID) ?? undefined;
    const dateSelect = searchParams.get(SearchParam.DATES_RANGE_TYPE_SELECT);
    const startDate = searchParams.get(SearchParam.START_DATE);
    const endDate = searchParams.get(SearchParam.END_DATE);

    const datesSet = Boolean(startDate && endDate);

    const loading = useAppSelector(state => state.batches.list.loading);

    /**
     * Is only a single day selected? 
     * (then we display the Times range select)
     */
    const singleDaySelected = useMemo(() => {
        if (!dateSelect) return false;
        return isSingleDay(dateSelect as DatesRangeType, moment(startDate), moment(endDate));
    }, [dateSelect, startDate, endDate]);

    return (
        <Grid
            container
            display="flex"
            spacing={1}
        >
            <FilterWrapper>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <DatesSelect
                            parentLoading={loading}
                            defaultValue={DatesRangeType.CUSTOM}
                        />
                    </Grid>

                    {singleDaySelected && (
                        <Grid item>
                            <TimesRangeSelect
                                parentLoading={loading}
                            />
                        </Grid>
                    )}
                </Grid>
            </FilterWrapper>
            <Grid item>
                <AreaSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <TruckSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <RouteSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <ErrorsSelect
                    parentLoading={loading}
                />
            </Grid>
        </Grid>
    );
}

export default ExplorerFilters;